import React from 'react';
import Logo from './Logo.js'

export const Header = ({parcel = {}}) => {
    const style = {
      header: {
        backgroundImage: 'url("/img/bgcanner.jpg")',
        backgroundSize: 'cover',
        backgroundColor: '#1E1E1E',
        paddingTop: '3em',
        paddingBottom: '2.5em'
      }, 
      banner: {
        marginTop: "-0.7em",
        textTransform: 'uppercase',
        color: '#FFFFFF',
        fontSize: '1rem',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      }
    };
    return (
      <div className="header container mb-5">
        <div className="row">
          <div className="col" style={style.header}> 
            <Logo parcel={parcel}/>
          </div>
        </div>
        <div className='row justify-content-center text-center' style={style.banner}>
          100% Recyclable - RÉ-EMPLOYABLE - Inviolable
        </div>
      </div>
    );
};

export default Header;


