import { useEffect } from 'react';

const TrackingPixel = ({ clientID, parcelID, event }) => {

  useEffect(() => {
    // Check if event is defined and not an empty string before triggering loadPixel
    if (event && event !== '') {
      loadPixel(clientID, parcelID, event);
    }
  }, [event, clientID, parcelID]);

  return null; // The component does not render anything itself
};

const loadPixel = (clientID, parcelID, event) => {
  let baseUrl = "";

  if (process.env.REACT_APP_DEBUG === "true") {
    console.log(process.env.REACT_APP_ENV);
  }

  if (process.env.REACT_APP_ENV === 'LCL') {
    baseUrl = process.env.REACT_APP_API_URL;
  } else if (process.env.REACT_APP_ENV === 'PRD') {
    baseUrl = "https://api.mobiuspack.io/v1/api/public";
  } else {
    baseUrl = "https://api-dev.mobiuspack.io/v1/api/public";
  }

  // Construct the pixel URL
  const pixelUrl = `${baseUrl}/client/pixel.svg?client_id=${clientID}&app_id=MP_FRONT_WEB&event=${event}&parcel_id=${parcelID}&timestamp=${Date.now()}`;

  // Create a new Image object and set the src to trigger the pixel load
  const img = new Image();
  img.src = pixelUrl;
};

export default TrackingPixel;
