import { Footer } from '../components/v2/Footer';
import { Header as HeaderV2 } from '../components/v2/Header';
import { useParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { getParcelInfo } from '../services/index.js';
import Carrousel from "../components/v2/carrousel";
import { ReactComponent as Chevron } from './../assets/chevron.svg';
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Hotjar from '@hotjar/browser';
import { observeHotjarModal } from '../helpers/hotjarUtils.js';

export function ReturnExchange() {
    let { parcelId } = useParams();
    const [parcel, setParcel] = useState({}); //Parcel data
    const [showModal, setShowModal] = useState(false); //If the video modal is shown
    const [sawSurvey, setSawSurvey] = useState(false); //If the survey was shown to the user
    const [exchangeButtonText, setExchangeButtonText] = useState('Obtenir mon étiquette retour'); //Text of the exchange button
    useEffect(() => {
        const datafetch = async () => {
            const data = await getParcelInfo(parcelId);
            process.env.REACT_APP_DEBUG === true && console.log(data);
            setParcel(data.results);
            setExchangeButtonText(data.results.clientExchangeProcess.name);
        };
        datafetch();
    }, [parcelId]);

    const style = {
        return: {
            backgroundColor: '#FFFFFF',
            height: '100%',
            width: '100%',
            padding: '0'
        },
        main: {
            paddingBottom: '12rem',
        },
        button: {
            borderRadius: '100px',
            padding: '0.8em',
            boxShadow: '0px 0px 16px 0px #878787',
            margin: ".5rem",
            fontSize: "1.2em",
            width: 'auto',
            alignItems: "center",
        },
        btnCont: {
            marginBottom: "9rem",
        }
    }; 
    const onClik = async () => {
        const clientURL = parcel.clientExchangeProcess.url;
        if (parcel.clientSurvey.b2c && !sawSurvey) {
            try {
                await Hotjar.init(siteId, hotjarVersion);
                // Lancer l'observation du DOM
                observeHotjarModal(clientURL);

                console.log("Hotjar loaded");
                setSawSurvey(true);
            } catch (e) {
                console.error("Hotjar failed to load", e);
                window.open(clientURL, '_blank');
                setSawSurvey(true);
            }
        } else {
            console.log("Hotjar not loaded (not the correct env, already loaded or disabled for this client.)");
            window.open(clientURL, '_blank');
        }
    };

    const siteId = 3803943;
    const hotjarVersion = 6;


    return (
        <div className="return" style={style.return}>
            <HeaderV2 content='article' />
            <div className="w-100 text-black" style={style.main}>
                <Carrousel content='article' />
                <div className="row d-flex justify-content-center text-center my-2 mx-2">
                    <div className="row">
                        <Button variant="secondary" className="fs-5 d-flex flex-grow-1 justify-content-center" onClick={() => setShowModal(true)} style={style.button}>
                            Voir la vidéo
                        </Button>
                    </div>
                    <div className="row">
                        <Button variant="primary" onClick={onClik} className="d-flex flex-grow-1 fs-5 text-white justify-content-center" style={style.button}>
                            {exchangeButtonText}
                            <Chevron />
                        </Button>
                    </div>
                </div>
            </div>
            <Footer content='article' tripCount={Number(parcel.usage)} tag={parcel.tag}/>
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Tutoriel vidéo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ position: 'relative', paddingTop: "177.77777777777777%" }}>
                        <iframe
                            src="https://customer-w3g4fv6ckfw8v1zh.cloudflarestream.com/45adb1de99afb45f9e0843d55e43bbfb/iframe?poster=https%3A%2F%2Fcustomer-w3g4fv6ckfw8v1zh.cloudflarestream.com%2F45adb1de99afb45f9e0843d55e43bbfb%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600"
                            loading="lazy"
                            style={{ border: 'none', position: 'absolute', top: 0, left: 0, height: '100%', width: '100%' }}
                            allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                            allowFullScreen
                            title='return video'
                            id='returnVideo'
                        ></iframe>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}