export function getParcelInfo(parcelID ="") {
  
  let baseUrl = ""
  
  process.env.REACT_APP_DEBUG === true && console.log(process.env.REACT_APP_ENV)

  if (process.env.REACT_APP_ENV === 'LCL') {
    baseUrl = process.env.REACT_APP_API_URL
  } 
  else if (process.env.REACT_APP_ENV === 'PRD') {
    baseUrl = "https://api.mobiuspack.io/v1/api/public"
  }
  else {
    baseUrl = "https://api-dev.mobiuspack.io/v1/api/public"
  }

    process.env.REACT_APP_DEBUG === true && console.log(baseUrl)
    return fetch(baseUrl+'/core/parcel/'+parcelID)
      .then(data => data.json())

}

export function getBaseURL() {

  let baseUrl = ""
  
  if (process.env.REACT_APP_ENV === 'LCL') {
    baseUrl = process.env.REACT_APP_URL
  } 
  else if (process.env.REACT_APP_ENV === 'PRD') {
    baseUrl = "https://ship.mobiuspack.io/"
  }
  else {
    baseUrl = "https://ship-dev.mobiuspack.io/"
  }

    return baseUrl

}