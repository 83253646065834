
export function mailTo(email ="") {
    
    process.env.REACT_APP_DEBUG === true && console.log("mailTo: " + email)

    let baseUrl = ""
    if (process.env.REACT_APP_ENV === 'LCL') {
      baseUrl = process.env.REACT_APP_API_URL
    } 
    else if (process.env.REACT_APP_ENV === 'PRD') {
      
      baseUrl = "https://api.mobiuspack.io/v1/api/public"
    }
    else {
      baseUrl = "https://api-dev.mobiuspack.io/v1/api/public"
    }
    const url = baseUrl+'/client/mailto'
    
    post(url, {
      email: email,
    })

}

const post = async (url, params) => {
  const response = await fetch(url, {
      method: 'POST',
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      body: JSON.stringify(params),
      headers: {
          'Content-type': 'application/json; charset=UTF-8',
      }
  })

  const data = await response.json()

  return data
}
