import React from "react";

const VideoEmbed = () => {
    const style = {
        videoResponsive: {
            overflow:"hidden",
            paddingBottom: "56.25%",
            position: "relative",
            height: "0"
        },
        iframe: {
            left: "0",
            top: "0",
            height: "100%",
            width: "100%",
            position: "absolute"
        }

    };
    return (
        <div className="video-responsive conatiner" style={style.videoResponsive}>
            <iframe
                src="https://customer-w3g4fv6ckfw8v1zh.cloudflarestream.com/726af14979a38cbb52b3093d9e41d584/iframe?muted=true&preload=true&poster=https%3A%2F%2Fcustomer-w3g4fv6ckfw8v1zh.cloudflarestream.com%2F726af14979a38cbb52b3093d9e41d584%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600"
                style={style.iframe}
                allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                allowFullScreen={true}
                title="return-bag-video"
            />
        </div>
    );
};
  
export default VideoEmbed;