import React from 'react';
import { ReactComponent as MPfull} from './../../assets/mobiuspack_logo_full.svg'
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { ReactComponent as BackBtn } from './../../assets/arrow-left-circle.svg'

export const Header = ({content = '', tripCount = 0}) => {
  
  let { parcelId } = useParams();

  let navigate = useNavigate();
  let goWebsite = useLocation().pathname === `/${parcelId}` ? true : false

  const style = {
    header: {
      backgroundSize: 'cover',
      backgroundColor: '#efeeee',
      margin: 'auto',
      padding: '0 3rem 1rem 1rem',
    },
    button: {
      backgroundColor: "#efeeee",
      padding: "1rem"
    },
    subtext: {
      textAlign: 'center',
    },
    title: {
      fontWeight: 750,
      fontSize: '1.5rem',
      lineHeight: '1.2',
      paddingBottom: '.5rem',
    },
    primaryText: {
      color: '#2ea9c7',
    }
  };

  const renderContent = () => {

    if(content === 'article') {
      return (
        <div className=''>
          <div className='title' style={style.title}>Pour commencer :</div>
          <h4>Retire l’étiquette de livraison et place <span style={style.primaryText}>l’étiquette retour !</span></h4>
        </div>
      )
    } else if (content === 'emballage') {
      return (
        <div className=''>
          <div className='title' style={style.title}>Pour commencer :</div>
          <h4>Retire l’étiquette d’expédition, c’est facile !</h4>
        </div>
      )
    } else if (content === 'identifiant') {
      if(!Number(tripCount)) {
        tripCount = 1;
      }
      return (
        <div>
          <div className='title col-10 col-sm-12' style={style.title}>Vous venez de recevoir votre commande dans un colis réemployable !</div>
          <div className='text-center'>Celui-ci a déjà réalisé <b>{String(tripCount)} expédition{tripCount>1?"s":""}.</b></div>
        </div>
      )
    } else if (content === 'home') {
      return (
        <div>
          <MPfull/>
        </div>
      )
    }

  }

  return (
    <div className="header container mb-10">
      <div className="row">
        <div style={style.button}>
          <BackBtn  onClick={() => goWebsite ? window.open('https://mobiuspack.com/', '_self') : navigate(-1)}/>
        </div>
        <div style={style.header}>             
          {renderContent()}
        </div>
      </div>
    </div>
  );

};

export default Header;


 