import React,{ useEffect, useState } from 'react';
import { ReactComponent as RenewIcon} from '../../assets/renew.svg';
import { ReactComponent as LeafIcon} from '../../assets/leaf.svg';
import { Link } from 'react-router-dom';

const ParcelInfo = ({parcel = {}}) => {  
  
  const [parcelExist, setParcelExist] = useState(0)

    useEffect(() => {
      let today = new Date();
      let date = new Date(parcel.creationDate)
      var difference = today.getTime() - date.getTime();
      var days = Math.ceil(difference / (1000 * 3600 * 24));
      
      if (days >= 0){
        setParcelExist(days)
      } else {
        setParcelExist(1)
      }
      return () => {}
    } , [parcel])

    const style = {
      icon: {
        maxHeight:"1em",
        color:"white"
      }
    }

    return (
        <div className="parcelinfo container">
          <div className="col">
            <div className="row justify-content-center text-center mt-4">
              <p className="text-white fs-5">Vous réutilisez un <br/> MOBIUSpack ! Merci.</p>
            </div>
            <div className="row justify-content-center text-center  mt-3">
              <p className="text-white fs-5"><RenewIcon style={style.icon} /> {parcel.usage >= 0 ? parcel.usage : 1} <br/> {parcel.usage > 1  ? "réemplois" : "réemploi"}</p>
            </div>
            <div className="row justify-content-center text-center  mt-3">
              <p className="text-white fs-5"> { parcel.impact === undefined ? 0: Math.round(parcel.impact * 100) / 100}kg de CO2 <br/><LeafIcon style={style.icon} /> évité</p>
            </div>
            <div className="row justify-content-center text-center  mt-3">
              <p className="text-white fs-5">{parcelExist > 1 ? parcelExist + " jours" : parcelExist + " jour"}  <br/> d'existence</p>
            </div>
            <div className="row justify-content-center text-center  mt-3">
              <img src="/img/travel.png" alt="travel" className="w-100"/>
            </div>
            <div className="row justify-content-center text-center my-5 mx-4">
              <Link to="/retours/location" className="btn btn-primary btn-lg active fs-5 text-uppercase text-white" style={style.button}>Retourner une location</Link>
            </div>
        </div>
      </div>
    );
};

export default ParcelInfo;
