import React from 'react';
import { ReactComponent as LogoMP} from '../../assets/MOBIUSPACK.svg';
import { ReactComponent as CrossIcon} from '../../assets/x-symbole.svg';


const Logo = ({parcel = {}}) => {

    const style = {
        logo: {
            margin: "0"
        },
        img: {
          mminWidth:"12em",
          maxWidth:"20em",
          margin: "1.2em 0 1.2em 0"
        },
        imgMP: {
          mminWidth:"12em",
          maxWidth:"12em",
          margin: "1.2em 0 1.2em 0"
        },
        icon: {
          maxHeight:"1.2em"
        }
    };

    const showLogo = parcel.lastClientLogo === "" ||  parcel.lastClientLogo === undefined ? false : true

    return (
      <div className="logo container">
        { showLogo
        ? (<div>
            <div  className="row justify-content-center text-center"  style={style.logo}>
              <div
                dangerouslySetInnerHTML={{ __html: parcel.lastClientLogo }}
                style={style.img}
              />
            </div>
            <div  className="row justify-content-center">
                <CrossIcon className="align-self-center" style={style.icon}/>
            </div>
        </div>)
        : ''
        }
        <div  className="row justify-content-center"  style={style.logo}>
            <LogoMP className="align-self-center" style={style.imgMP}/>
        </div>
      </div>
    );
};

export default Logo;