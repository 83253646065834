import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import ECHANGE1 from './../../assets/carrousel/ECHANGE-1.svg'
import ECHANGE2 from './../../assets/carrousel/ECHANGE-2.svg'
import ECHANGE3 from './../../assets/carrousel/ECHANGE-3.svg'
import ECHANGE4 from './../../assets/carrousel/ECHANGE-4.svg'
import ECHANGE5 from './../../assets/carrousel/ECHANGE-5.svg'
import ECHANGE6 from './../../assets/carrousel/ECHANGE-6.svg'
import ECHANGE7 from './../../assets/carrousel/ECHANGE-7.svg'
import RETOUR1 from './../../assets/carrousel/RETOUR-1.svg'
import RETOUR2 from './../../assets/carrousel/RETOUR-2.svg'
import RETOUR3 from './../../assets/carrousel/RETOUR-3.svg'
import RETOUR4 from './../../assets/carrousel/RETOUR-4.svg'
import RETOUR5 from './../../assets/carrousel/RETOUR-5.svg'


export function Carrousel ({content = ''}){

  const style = {
      carrousel: {
          margin: "auto",
          marginTop: ".3rem",
      },
      carrouselImage: {
        height: '23rem',
        // backgroundImage: "linear-gradient(180deg, #FFFFFFFF 0%, #e4e4e4 100%)",
        // backgroundSize: 'cover',
        // backgroundRepeat: 'no-repeat',
        borderRadius: '2.5rem',
        // border: '1px solid #e4e4e4',
        boxShadow: '0px 0px 16px 0px #878787',
        marginBottom: '3rem',
      },
      carouselItem: {
        // backgroundImage: "linear-gradient(180deg, #FFFFFFFF 0%, #e4e4e4 100%)",
        // backgroundSize: 'cover',
        // backgroundRepeat: 'no-repeat',
        // borderRadius: '1rem',
        // border: '1px solid #e4e4e4',
        // boxShadow: '0px 0px 16px 0px #878787',
      },
  };

  const renderContent = (content) => {
    let carouselItems = []
    if( content === 'emballage') {
      carouselItems = [ECHANGE1, ECHANGE2, ECHANGE3, ECHANGE4, ECHANGE5, ECHANGE6, ECHANGE7]
      return carouselItems.map((item, index) => (
          <Carousel.Item key={index}>
            <img
              className="d-block  mx-auto d-block"
              src={item}
              alt={index}
              style={style.carrouselImage}
            />
            {/* <Carousel.Caption style={style.caption}>
              <h3>First slide label</h3>
              <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
            </Carousel.Caption> */}

          </Carousel.Item>
      ));
    } else if (content === 'article') {
      carouselItems = [RETOUR1, RETOUR2, RETOUR3, RETOUR4, RETOUR5]
      return carouselItems.map((item, index) => (
        <Carousel.Item key={index}>
          <img
            className="d-block mx-auto d-block"
            src={item}
            alt={index}
            style={style.carrouselImage}
          />
        {/* <Carousel.Caption>
          <h3>First slide label</h3>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </Carousel.Caption> */}

        </Carousel.Item>
      ));
    }
    else {
      return (<div></div>)
    }

  }

  return <div className="carrousel container" data-ride="carousel" style={style.carouselItem} >
    <Carousel data-bs-theme="dark" interval={null} style={style.carrousel}>
      {renderContent(content)}
    </Carousel>
     </div>
}

export default Carrousel;



