export const observeHotjarModal = (clientURL) => {
    // Fonction pour observer les changements dans le DOM
    const observer = new MutationObserver(() => {
        const modalCloseButton = document.querySelector("._hj-x7hBM__styles__closeModalBtn");
        if (modalCloseButton) {
            modalCloseButton.addEventListener("click", () => {
                window.open(clientURL, '_blank');
            });
            observer.disconnect();
        }
    });
    

    // Commence à observer les changements dans le body
    observer.observe(document.body, {
        childList: true, // Observer l'ajout ou suppression d'éléments enfants
        subtree: true    // Inclure tout le sous-arbre
    });
};

export const isHotjarBlocked = async () => {
    // Vérifie si Hotjar est bloqué par l'utilisateur
    let adBlockEnabled = false;

    const hotjarAdsURL =
        'https://static.hotjar.com/';

    try {
        await fetch(new Request(hotjarAdsURL)).catch(_ => {
        adBlockEnabled = true;
        });
    } catch (err) {
        adBlockEnabled = true;
    } finally {
        console.log(`AdBlock enabled: ${adBlockEnabled}`);
        return adBlockEnabled;
    }

}