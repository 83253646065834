import React, { useState } from 'react';
import { mailTo}  from '../../services/index.js';

const EmailForm = () => {

  const [email, setEmail] = useState('');

  const clickOnMail= ((e) => {
    e.preventDefault()
    mailTo(email)
    setEmail('')
  })

  return (
    <div className="emailForm container">
      <div className="row justify-content-center mt-5">
          <form>
              <div className="form-group col mx-4">
                  <label className='fs-5 text-white'>Renseignez votre email et nous vous contacterons.</label>
                  <input type="email" value={email} onChange={e => setEmail(e.target.value)} className="form-control my-4" id="exampleFormControlInput1" placeholder="tony.stark@mobiuspack.com"/>
                  <button type="submit" onClick={clickOnMail} className="btn btn-primary fs-5 text-white text-uppercase w-100">Envoyer mon email</button>
              </div>
          </form>
      </div>
    </div>
  );
};

export default EmailForm;

