import VideoEmbed from "../components/v1/VideoEmbed";
import Footer from '../components/v1/Footer';
import Header from '../components/v1/Header';

export function ReturnLocation() {

    const style = {
        return : {
            backgroundColor: '#1E1E1E',
            height: 'auto',
            width: '100%',
            marginBottom: '7rem',
            padding: '0'
          }
      }
    
    return (
        <div className="return" style={style.return}>
            <Header parcel={{}}/>
            <VideoEmbed />
            <div className="w-100 text-white p-4" >
                <ol className="px-4">
                    <li className="py-3 fs-7">Commencer par enlever l’ancienne étiquette transport.</li>
                    <li className="py-3 fs-7">Coller ensuite l’étiquette fournie par le transporteur</li>
                    <li className="py-3 fs-7">Mettre l'article au fond du sac.</li>
                    <li className="py-3 fs-7">Prendre le scellé situé dans la bande haute de l’emballage (voir la vidéo).</li>
                    <li className="py-3 fs-7">Déplier le scellé et insérer la boucle mâle dans ce dernier.</li>
                    <li className="py-3 fs-7">Rouler le haut du sac 3 fois.</li>
                    <li className="py-3 fs-7">Fermer la boucle.</li>
                    <li className="py-3 fs-7">Déposer votre colis dans un point relais. 🎉</li>
                </ol>
            </div>
            < Footer content='emballage'/> 
        </div>
    );

}