import React from 'react'
import { useNavigate, useLocation } from "react-router-dom";

export function Footer (props){

    let navigate = useNavigate();
    let goBackHome = useLocation().pathname === '/retours/location' ? true : false

    const style = {
        footer: {
            marginTop: props.marginTop,
            marginBottom: '0px',
            minHeight: '4rem',
            bottom: '0',
            backgroundColor: 'black'
        }
    };

  return <div className="footer container" style={style.footer}>
            <div className="row">
                <div className="col align-self-end mt-4"> 
                {goBackHome
                    ? <div className="fs-6 text-center text-white" onClick={() => navigate(-1)}>Retour</div>
                    : ''
                }
                </div>
            </div>
        </div>
}

export default Footer;
