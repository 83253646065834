import React,{ useState } from 'react';

const KnowMore = ({parcel = {weight: 0, height: 0, width: 0, volume: 0, size: "" }}) => {
    const [openDetailsMenu, setOpenDetailsMenu] = useState(false);

    const menuClass = `collapse ${openDetailsMenu ? " show" : ""}`;
    
    const style = {
        button: {
            backgroundColor: '#D6CDC0',
            whiteSpace: 'normal',
            wordWrap: 'break-word',
        }
    }

    // Set default value based on light M if needed
    if( (parcel.height === undefined && parcel.width === undefined) || (parcel.height === 0 && parcel.width === 0) ) {
        parcel = { 
            type: "LIGHT",
            EAN: "PF",
            size: "M",
            weight: 150, 
            height: 490, 
            width: 618, 
            volume: 20 
        }
    }

    return (
        <div className='knowmore container'>
            <div className='row mx-3 my-2'>
                <div className='text-white fs-5 text-uppercase font-weight-bold'>Vous souhaitez en savoir plus ? </div>
            </div>
            <div className=" row card bg-transparent mx-4 border-0">
                    <button className="btn dropdown-toggle align-self-center fs-5" type="button" style={style.button} onClick={() => {setOpenDetailsMenu(!openDetailsMenu)}}> 
                        Voir les caractéristiques de votre MOBIUSpack
                    </button>  
                    <div id="collapseOne" className={menuClass} aria-labelledby="headingOne" data-parent="#accordionExample">
                        <div className="card-body p-1">
                            <ul className="list-group p-0">
                                <li className="list-group-item">Modèle : {parcel.type.toUpperCase()} (ref: {parcel.EAN.toUpperCase()}) </li>
                                <li className="list-group-item">Taille : {parcel.size.toUpperCase()} </li>
                                <li className="list-group-item">Dimensions : {parcel.width+" x "+ parcel.height +"mm"}</li>
                                <li className="list-group-item">Poids : {parcel.weight+"g"}</li>
                                <li className="list-group-item">Volume : {parcel.volume+"L"} </li>
                                <li className="list-group-item">Origine : France</li>
                            </ul>   
                        </div>
                    </div>
            </div>
        </div>
    );
};

export default KnowMore;
