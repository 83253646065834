import { ReturnLocation } from '../pages/ReturnLocation';
import { ReturnParcel } from '../pages/ReturnParcel';
import { ReturnExchange } from '../pages/ReturnExchange';
import { ParcelChoices } from '../pages/ParcelChoices';
import { Home } from '../pages/Home';
import {Outlet, createBrowserRouter } from 'react-router-dom';

function Root () {
    return <Outlet/>

}

const Router = createBrowserRouter([
{
    path: '/',
    element: <Root />,
    children: [
    {
        path: ':parcelId',
        element: <ParcelChoices /> 
    },
    {
        path: 'v2/:parcelId',
        element: <ParcelChoices /> 
    },
    {
        path: 'retours/location',
        element: <ReturnLocation />, 
    },
    {
        path: 'retours/emballage/:parcelId',
        element: <ReturnParcel />, 
    },
    {
        path: 'retours/article/:parcelId',
        element: <ReturnExchange />, 
    },  
    {
        path: 'rexpedition',
        element: <Home />,
    },
    {
        path: '/',
        element: <Home />,
    }
    ],
}
]);

export default Router